import { get } from 'lodash'
import PageHomepage from '~/themes/base/components/shop/PageHomepage/PageHomepage.js'
import Slider from '@theme/components/content/Slider'

export default {
  mixins: [PageHomepage],
  computed: {
    criticalContentBreakpoints() {
      return [
        { viewportMinWidth: 991, imageWidth: 1920, imageHeight: 450 },
        { viewportMinWidth: 320, imageWidth: 1000, imageHeight: 600 },
      ]
    },
    criticalContentImageRatio() {
      const width = this.$themeSettings.components.Slider.image.width
      const height = this.$themeSettings.components.Slider.image.height
      return 1 / (width / height)
    },
    criticalContentPaths() {
      const elements = []

      if (this.featuredBanners.length > 0) {
        const banner = this.featuredBanners[0]
        elements.push(
          banner.main_image_translatable && banner.main_image_translatable.length > 0
            ? banner.main_image_translatable[0].path
            : banner.main_image[0].path,
        )
      }

      return elements
    },
  },
}
